import React, { MouseEventHandler, useEffect, useState } from 'react';

import classNames from 'classnames';

import {
  calculateCurrentPagesChunk,
  calculateNextPagesChunkRange,
  calculatePreviousPagesChunkRange,
  ChunkPagesRange,
} from './utils';

import { useTranslation } from '@external/react-i18next';

export interface PaginationItems {
  page?: number;
  pageSize?: number;
  pagesChunkSize?: number;
  totalCount: number;
  pageHandler: (event: React.SyntheticEvent, page: number) => void;
}

const PreviousNext: React.FC<{
  arrowType: string;
  disabled: boolean;
  clickHandler?: (event: React.SyntheticEvent) => void;
  dataTestId?: string;
}> = ({ arrowType, disabled, clickHandler, dataTestId = '' }) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={dataTestId}
      onClick={
        (!disabled ? clickHandler : null) as MouseEventHandler<HTMLDivElement>
      }
      className={classNames('inline', {
        'pointer-events-none': disabled,
      })}
    >
      <span className="sr-only">
        {arrowType === 'previous'
          ? t('search.pagination.previous', 'previous set of pages')
          : t('search.pagination.next', 'next set of pages')}
      </span>
      <span
        aria-hidden="true"
        className={disabled ? 'text-gray-300' : 'text-gray-400'}
      >
        <svg
          className="inline-block"
          width="10"
          height="16"
          viewBox="0 0 16 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          {arrowType === 'previous' && (
            <path d="M16 21L12.7392 24L1.04907e-06 12L12.7392 -2.85068e-07L16 3L6.4 12L16 21Z" />
          )}
          {arrowType === 'next' && (
            <path d="M0 3L3.2608 0L16 12L3.2608 24L0 21L9.6 12L0 3Z" />
          )}
        </svg>
      </span>
    </div>
  );
};

export const Pagination: React.FC<PaginationItems> = ({
  page = 1,
  pageSize = 20,
  pagesChunkSize = 20,
  totalCount,
  pageHandler,
}) => {
  const { t } = useTranslation();
  const totalPages = Math.ceil(totalCount / pageSize);
  const singlePage = totalPages < 2;

  const [previousPagesChunkRange, setPreviousPagesChunkRange] = useState<
    ChunkPagesRange
  >(null);
  const [currentPagesChunk, setCurrentPagesChunk] = useState<number[]>([1]);
  const [nextPagesChunkRange, setNextPagesChunkRange] = useState<
    ChunkPagesRange
  >(null);

  useEffect(() => {
    setPreviousPagesChunkRange(
      calculatePreviousPagesChunkRange(page, pagesChunkSize)
    );
    setCurrentPagesChunk(
      calculateCurrentPagesChunk(page, pagesChunkSize, totalPages)
    );
    setNextPagesChunkRange(
      calculateNextPagesChunkRange(page, pagesChunkSize, totalPages)
    );
  }, [page, pageSize, pagesChunkSize, totalPages]);

  return (
    <nav aria-label="pagination" className="text-xs mt-16 text-center">
      <PreviousNext
        disabled={singlePage || page === 1}
        arrowType="previous"
        clickHandler={(event: React.SyntheticEvent) =>
          pageHandler(event, page - 1)
        }
        dataTestId="pagination-prev-btn"
      />
      <label htmlFor="pagination" className="sr-only">
        {t('search.pagination.go-to', 'Go to')}
      </label>
      <select
        name="pages"
        data-testid="pagination-component"
        id="pagination"
        className={`appearance-none cursor-pointer text-last-right leading-loose ml-20 pr-6 focus-visible:outline-0 focus:shadow-none ${
          singlePage ? 'pl-6 mr-20' : ''
        } relative bg-transparent`}
        value={page}
        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
          pageHandler(event, Number(event.currentTarget.value));
        }}
      >
        {previousPagesChunkRange && (
          <option
            key="previousPagesChunkRange"
            value={previousPagesChunkRange[1]}
          >
            {t('search.pagination.pages', 'Pages')}{' '}
            {previousPagesChunkRange.join('-')}
          </option>
        )}
        {currentPagesChunk.map(pageNumber => (
          <option
            key={pageNumber}
            value={pageNumber}
            aria-current={page === pageNumber ? 'page' : 'false'}
          >
            {t('search.pagination.page', 'Page')} {pageNumber}
          </option>
        ))}
        {nextPagesChunkRange && (
          <option key="nextPagesChunkRange" value={nextPagesChunkRange[0]}>
            {nextPagesChunkRange[0] === nextPagesChunkRange[1]
              ? `${t('search.pagination.page', 'Page')} ${
                  nextPagesChunkRange[0]
                }`
              : `${t(
                  'search.pagination.pages',
                  'Pages'
                )} ${nextPagesChunkRange.join('-')}`}
          </option>
        )}
      </select>
      {!singlePage && (
        <span className="-ml-6 mr-20 text-bright-blue-600">
          <svg
            className="inline-block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </span>
      )}
      <PreviousNext
        dataTestId="pagination-next-btn"
        disabled={singlePage || page === totalPages}
        arrowType="next"
        clickHandler={(event: React.SyntheticEvent) =>
          pageHandler(event, page + 1)
        }
      />
    </nav>
  );
};
