import React, { useState } from 'react';

import { useField } from 'formik';

import Element, { ElementProps } from '@components/Formik/Element';
import PlacesAutocomplete from '@components/Google/PlacesAutocomplete';

import LocationDescription from './LocationDescription';
import LocationInput from './LocationInput';
import LocationReset from './LocationReset';
import LocationSuggestions from './LocationSuggestions';

import { useTranslation } from '@external/react-i18next';

interface MemberLocationSelectProps extends ElementProps {
  name: string;
  disabled?: boolean;
  placeholder?: string;
  resettable?: boolean;
}

const MemberLocationSelect: React.FC<MemberLocationSelectProps> = props => {
  const { name, label, placeholder, resettable, disabled } = props;
  const { t } = useTranslation();
  const [enableSuggestions, setEnableSuggestions] = useState(false);

  const [field, meta, helpers] = useField(name);

  const [pin, setPin] = useState(false);

  const handleChangeLocation = (value: string) => {
    if (!value) {
      setPin(false);
    }
    helpers.setValue(value);
    setEnableSuggestions(true);
  };

  const handleSelectLocation = (value: string) => {
    if (value) {
      helpers.setValue(value);
      setPin(true);
      setEnableSuggestions(true);
    }
  };

  const handleClearLocation = () => {
    helpers.setValue('');
    setPin(false);
    setEnableSuggestions(true);
  };

  return (
    <Element {...props}>
      {typeof window !== 'undefined' && (
        <PlacesAutocomplete
          value={field.value || ''}
          onChange={handleChangeLocation}
          onSelect={handleSelectLocation}
          googleCallbackName="initMemberLocationSelector"
          searchOptions={{ types: ['locality', 'country'] }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <>
              <LocationInput
                name={name}
                placeholder={placeholder}
                pin={pin}
                isDisabled={disabled}
                isResettable={resettable}
                defaultClassNames="club-location-select"
                defaultInputProps={getInputProps({ disabled })}
                metaFields={[meta]}
              />

              <LocationDescription
                customDescription={t(
                  'member-search.form.location.description',
                  'Example: “Tokyo, Japan”'
                )}
              />

              {enableSuggestions && (
                <LocationSuggestions
                  suggestions={suggestions}
                  getSuggestionItemProps={getSuggestionItemProps}
                />
              )}
            </>
          )}
        </PlacesAutocomplete>
      )}
      {resettable && field.value && (
        <LocationReset handleClick={handleClearLocation} label={label} />
      )}
    </Element>
  );
};

export default MemberLocationSelect;
