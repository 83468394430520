import React, { useState } from 'react';

import { Avatar } from '@components/Avatar';
import Icon from '@components/Icon';

import DisplayHonoraryMemberships from './DisplayHonoraryMemberships';
import DisplayMembershipRoles from './DisplayMembershipRoles';

import {
  MEMBERSHIP_TYPE_HONORARY,
  MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
  MEMBERSHIP_TYPE_MEMBER,
  MEMBERSHIP_TYPE_ROTARACTOR,
} from '@domain/districts';

import {
  getActiveMemberShip,
  getClubsByRoles,
  getDistrictByRole,
  isMembershipEmpty,
} from '@use-cases/clubs';

import { LinkWithState as LocalizedLink } from '@utils/localized-link';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';
import { ScreenSizes, useScreenSizeLayout } from '@hooks/useScreenSizeLayout';

import { DIS } from '@typings/dis';
import { MemberSearchResultFragment } from '@typings/operations';

type MemberSearchResultProps = MemberSearchResultFragment & {
  searchRank: number;
};

const MemberSearchResult: React.FC<MemberSearchResultProps> = ({
  id,
  name,
  localizedName,
  email,
  location,
  activeMemberships,
  activeLeaderships,
  thisDistrictLeadership,
  photoUri,
  searchRank,
}) => {
  const { t } = useTranslation();
  const { fireTrackingEvent } = useAnalytics();

  const trackClick = (destination: string) => {
    fireTrackingEvent('searchResultClicked', {
      searchResultDestination: destination,
      searchType: 'Member Search',
      searchResultIndex: searchRank,
    });
  };

  const membershipType = [MEMBERSHIP_TYPE_MEMBER, MEMBERSHIP_TYPE_ROTARACTOR];
  const honoraryMembershipType = [
    MEMBERSHIP_TYPE_HONORARY,
    MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
  ];
  const terminateLabel = t('membership.terminated', 'Terminated');

  const districtsByRole = getDistrictByRole(thisDistrictLeadership);
  const rotaryClubsByRoles = getClubsByRoles(
    activeLeaderships,
    DIS.OrganizationTypeEnum.Rotary_Club,
    t
  );
  const rotaractClubsByRoles = getClubsByRoles(
    activeLeaderships,
    DIS.OrganizationTypeEnum.Rotaract_Club,
    t
  );

  const onlyActiveMembership =
    isMembershipEmpty(rotaryClubsByRoles) &&
    isMembershipEmpty(rotaractClubsByRoles);
  const activeMembership = getActiveMemberShip(
    activeMemberships,
    membershipType,
    terminateLabel
  );

  const onlyHonoraryMember =
    onlyActiveMembership &&
    isMembershipEmpty(activeMembership) &&
    isMembershipEmpty(districtsByRole);
  const honoraryMemberships = getActiveMemberShip(
    activeMemberships,
    honoraryMembershipType,
    terminateLabel
  );

  const isMobile = useScreenSizeLayout(ScreenSizes.mobile);
  const isMobileL = useScreenSizeLayout(ScreenSizes.mobileL);
  const isMobileM = useScreenSizeLayout(ScreenSizes.mobileM);
  const isMobileScreen = isMobile || isMobileM || isMobileL;

  const [isMobileCollapseOpen, setIsMobileCollapseOpen] = useState(false);
  const hasMultipleHonoraryMembershipForMobile =
    isMobileScreen &&
    !isMembershipEmpty(honoraryMemberships) &&
    !onlyHonoraryMember;
  return (
    <div data-label="SearchResult" className="flex">
      <Avatar photoUri={photoUri || undefined} className="flex-none" />

      <div className="grow-1">
        <div className="flex justify-between">
          <LocalizedLink
            to={`/profile/${id}`}
            onClick={() => trackClick(`/profile/${id}`)}
          >
            <h3 className="mb-0">{name}</h3>
            {localizedName && <div>{localizedName}</div>}
          </LocalizedLink>
          {hasMultipleHonoraryMembershipForMobile && (
            <div className="inline-flex items-center mr-3 mb-6">
              <span
                className="cursor-pointer"
                onClick={() => setIsMobileCollapseOpen(!isMobileCollapseOpen)}
                data-testid="honorary-membership-toggle-expand-btn"
              >
                <Icon
                  className="inline-block mr-3"
                  name={isMobileCollapseOpen ? 'ui/close' : 'arrows/down'}
                  size="15"
                  color="black"
                />
              </span>
            </div>
          )}
        </div>
        {location && <p className="mb-2 text-sm">{location}</p>}

        <DisplayMembershipRoles membershipRoles={districtsByRole} />
        <DisplayMembershipRoles membershipRoles={rotaryClubsByRoles} />
        <DisplayMembershipRoles membershipRoles={rotaractClubsByRoles} />
        {onlyActiveMembership && (
          <DisplayMembershipRoles membershipRoles={activeMembership} />
        )}
        <DisplayHonoraryMemberships
          honoraryMemberships={honoraryMemberships}
          onlyHonoraryMember={onlyHonoraryMember}
          isMobileScreenSize={isMobileScreen}
          isMobileCollapseOpen={isMobileCollapseOpen}
          email={email}
          trackClick={trackClick}
        />
        {email && (!isMobileScreen || !hasMultipleHonoraryMembershipForMobile) && (
          <a
            href={`mailto:${email}`}
            className="mb-2 text-sm"
            onClick={() => trackClick(`mailto:${email}`)}
          >
            {email}
          </a>
        )}
      </div>
    </div>
  );
};

export default MemberSearchResult;
