import React from 'react';

import { useField } from 'formik';

import Select, { SelectOptions, SelectProps, useOptions } from '../Select';

import { useRoleOptions } from '@use-cases/clubs';

export type SelectTerminationReasonProps = Omit<SelectProps, 'options'> & {
  clubType: string;
};

export type RoleNamesSelectProps = Omit<SelectProps, 'options'> & {
  organizationType?: string[];
  validOn?: Date | string;
  isGlobalMemberSearch?: boolean;
};

export const RoleSelect: React.FC<RoleNamesSelectProps> = props => {
  const { onChange, organizationType, validOn, isGlobalMemberSearch } = props;

  const [districtRoleField, , districtRoleHelper] = useField('districtRole');

  const useContextualRoleOptions: useOptions = () =>
    useRoleOptions(organizationType, validOn, isGlobalMemberSearch);

  const onChangeHandler = (
    option: string,
    options?: useOptions | SelectOptions
  ) => {
    const selectedItem = (options as SelectOptions).find(
      item => item.value === option
    );
    if (selectedItem?.type === 'District') {
      districtRoleHelper.setValue(selectedItem.value);
    } else {
      districtRoleField.value && districtRoleHelper.setValue('');
    }
    onChange?.(option, options);
  };
  return (
    <Select
      {...props}
      onChange={onChangeHandler}
      options={useContextualRoleOptions}
    />
  );
};
