import { range } from 'lodash';

export type ChunkPagesRange = [number, number] | null;

export const calculatePreviousPagesChunkRange = (
  currentPage: number,
  pagesChunkSize: number
): ChunkPagesRange => {
  const previousChunksCount = Math.ceil(currentPage / pagesChunkSize) - 1;
  const previousChunkStart = (previousChunksCount - 1) * pagesChunkSize + 1;
  const previousChunkEnd = previousChunksCount * pagesChunkSize;

  return previousChunksCount ? [previousChunkStart, previousChunkEnd] : null;
};

export const calculateCurrentPagesChunk = (
  currentPage: number,
  pagesChunkSize: number,
  totalPages: number
): number[] => {
  const previousChunkEnd =
    (Math.ceil(currentPage / pagesChunkSize) - 1) * pagesChunkSize;
  const currentChunkStart =
    previousChunkEnd === currentPage
      ? previousChunkEnd - pagesChunkSize + 1
      : previousChunkEnd + 1;
  const currentChunkEnd =
    totalPages - previousChunkEnd > pagesChunkSize
      ? previousChunkEnd + pagesChunkSize
      : totalPages;

  return range(currentChunkStart, currentChunkEnd + 1);
};

export const calculateNextPagesChunkRange = (
  currentPage: number,
  pagesChunkSize: number,
  totalPages: number
): ChunkPagesRange => {
  const currentChunkLastPage =
    Math.ceil(currentPage / pagesChunkSize) * pagesChunkSize;
  let pagesLeftForNextChunks = totalPages - currentChunkLastPage;
  pagesLeftForNextChunks =
    pagesLeftForNextChunks < 0 ? 0 : pagesLeftForNextChunks;
  const nextChunkStart = currentChunkLastPage + 1;
  const nextChunkEnd =
    pagesLeftForNextChunks > pagesChunkSize
      ? currentChunkLastPage + pagesChunkSize
      : totalPages;

  return pagesLeftForNextChunks ? [nextChunkStart, nextChunkEnd] : null;
};
