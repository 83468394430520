import * as React from 'react';
import { ReactNode } from 'react';

import classNames from 'classnames';

export const TwoColumns: React.FC<{
  small: ReactNode;
  big: ReactNode;
  className?: string;
  bigColumnClassName?: string;
}> = ({ small, big, className, bigColumnClassName }) => (
  <div
    data-label="TwoColumns"
    className={classNames(
      'tablet:flex OneColumn desktop:pl-32 desktop:pr-32 mobile:pl-0 mobile:pr-0',
      className
    )}
  >
    <div className="flex-1 desktop-l:min-w-24 pl-8 desktop:pl-8 desktop-l:pl-center-three-columns pr-8 desktop-l:pr-8 py-12 bg-gray-200 border-gray-300 border-r">
      {small}
    </div>
    <div
      className={classNames(
        'flex-2 py-12 px-6 desktop-l:pr-center-one-columns',
        bigColumnClassName
      )}
    >
      {big}
    </div>
  </div>
);
