import React, { PropsWithChildren, useState } from 'react';

import Collapse from '@kunukn/react-collapse';

import Icon from '@components/Icon';

export type HonoraryMembershipCollapseHeaderProps = {
  title: string;
  collapseInitialState: boolean;
  children: React.ReactNode;
};

const HonoraryMembershipCollapseHeader = ({
  title,
  collapseInitialState,
  children,
}: PropsWithChildren<HonoraryMembershipCollapseHeaderProps>) => {
  const [isOpen, setIsOpen] = useState(collapseInitialState);
  return (
    <>
      <div className="mr-auto inline-flex items-center">
        <span
          className="cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
          data-testid="honorary-membership-toggle-expand-btn"
        >
          <Icon
            className="inline-block mr-3"
            name={`squares/${isOpen ? 'icon-collapse' : 'icon-expand'}`}
            size="15"
            color="dark-blue-400"
          />
        </span>
        <div className="desktop:flex desktop:flex-1 desktop:items-center">
          <span
            className="cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
            dangerouslySetInnerHTML={{ __html: `${title}` }}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen} className="collapse-css-transition mb-4 pt-2">
        {children}
      </Collapse>
    </>
  );
};

export default HonoraryMembershipCollapseHeader;
