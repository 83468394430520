import React from 'react';

import Select, { SelectProps } from '../Select';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

export const MemberTypeSelect: React.FC<Omit<
  SelectProps,
  'options'
>> = props => {
  const { t } = useTranslation();
  const options = [
    {
      label: t('member-type.rotary-member', 'Rotary Member'),
      value: MembershipType.Member,
    },
    {
      label: t('member-type.rotaract-member', 'Rotaract Member'),
      value: MembershipType.Rotaractor,
    },
    {
      label: t('member-type.honorary-rotarian', 'Honorary Rotarian'),
      value: MembershipType.Honorary,
    },
    {
      label: t('member-type.honorary-rotaractor', 'Honorary Rotaractor'),
      value: MembershipType.RotaractHonorary,
    },
  ];
  return (
    <Select
      {...props}
      placeholder={t('member-type-select.find-by-member-name', 'Select')}
      options={options}
      filterOption={() => true}
    />
  );
};
