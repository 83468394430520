import { useState } from 'react';

export const useReset = () => {
  const [resetFlag, setResetFlag] = useState(false);

  const handleReset = () => !resetFlag && setResetFlag(!resetFlag);

  return {
    setResetFlag,
    resetFlag,
    handleReset,
  };
};
