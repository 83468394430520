import React from 'react';

import { isMembershipEmpty } from '@use-cases/clubs';

type DisplayMembershipRolesProps = {
  membershipRoles: Record<string, string[]>;
};

const DisplayMembershipRoles: React.FC<DisplayMembershipRolesProps> = ({
  membershipRoles,
}) => {
  return (
    <div>
      {!isMembershipEmpty(membershipRoles) &&
        Object.entries(membershipRoles).map(([club, roles]) => (
          <div key={club}>
            <p className="mb-2 mt-2 font-bold text-sm club-type">
              {club},
              <span className="mb-2 mt-2 text-sm roles">
                {' '}
                {roles.join(', ')}
              </span>
            </p>
          </div>
        ))}
    </div>
  );
};

export default DisplayMembershipRoles;
