import React, { forwardRef } from 'react';

import { MemberSearchFormValues } from '@domain/clubs';

import { useTranslation } from '@external/react-i18next';

type MemberSearchSummaryProps = MemberSearchFormValues & {
  showing: number;
  total: number;
  numberOfFilters?: number;
};

const MemberSearchSummary = forwardRef<
  HTMLParagraphElement,
  MemberSearchSummaryProps
>(({ showing, total, name, email, numberOfFilters, children }, ref) => {
  let nameEmail: string | undefined;
  if (name || email) {
    // Use "name, email" if both are given or just one.
    nameEmail = [name, email].filter(Boolean).join(', ');
  }

  const { t } = useTranslation();

  const getSearchMessage = () => {
    if (!nameEmail && !numberOfFilters) {
      return t(
        'members-search.resultsSummary.showing',
        'Showing {{ showing }} of {{ total }} members',
        { showing, total, count: total }
      );
    }

    if (!numberOfFilters) {
      return t(
        'search.resultsSummary.showingForName',
        'Showing {{ showing }} of {{ total }} members for <strong>{{ nameEmail }}</strong>',
        { showing, total, nameEmail, count: total }
      );
    }

    if (numberOfFilters === 1) {
      if (!nameEmail) {
        return t(
          'search.resultsSummary.showingWithSingleFilter',
          'Showing {{ showing }} of {{ total }} members with <strong>{{ numberOfFilters }}</strong> filter applied',
          { showing, total, numberOfFilters, count: total }
        );
      }

      return t(
        'search.resultsSummary.showingForNameWithSingleFilter',
        'Showing {{ showing }} of {{ total }} members for <strong>{{ nameEmail }}</strong> with <strong>{{ numberOfFilters }}</strong> filter applied',
        { showing, total, nameEmail, numberOfFilters, count: total }
      );
    }

    if (!nameEmail) {
      return t(
        'search.resultsSummary.showingWithMultipleFilters',
        'Showing {{ showing }} of {{ total }} members with <strong>{{ numberOfFilters }}</strong> filters applied',
        { showing, total, numberOfFilters, count: total }
      );
    }

    return t(
      'search.resultsSummary.showingForNameWithMultipleFilters',
      'Showing {{ showing }} of {{ total }} members for <strong>{{ nameEmail }}</strong> with <strong>{{ numberOfFilters }}</strong> filters applied',
      { showing, total, nameEmail, numberOfFilters, count: total }
    );
  };

  return (
    <p className="text-xs leading-xs mb-0" ref={ref}>
      {getSearchMessage()}
      {children}
    </p>
  );
});

export default MemberSearchSummary;
