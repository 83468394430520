import React from 'react';

import HonoraryMembershipCollapseHeader from './HonoraryMembershipCollapseHeader';
import HonoraryMembershipMobileCollapseHeader from './HonoraryMembershipMobileCollapseHeader';

import { isMembershipEmpty } from '@use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

export type DisplayHonoraryMembershipsProps = {
  honoraryMemberships: Record<string, string[]>;
  onlyHonoraryMember: boolean;
  isMobileScreenSize: boolean;
  isMobileCollapseOpen: boolean;
  email: string | null | undefined;
  trackClick: (destination: string) => void;
};

const DisplayHonoraryMemberships: React.FC<DisplayHonoraryMembershipsProps> = ({
  honoraryMemberships,
  onlyHonoraryMember,
  isMobileScreenSize,
  isMobileCollapseOpen,
  email,
  trackClick,
}) => {
  const { t } = useTranslation();
  const honoraryMembershipsTitle = t(
    'member-search.member-search-results.honorary-collapse-header.title',
    'Honorary Membership(s)'
  );

  const collapseHeader = isMobileScreenSize ? (
    <HonoraryMembershipMobileCollapseHeader
      honoraryMembershipsTitle={honoraryMembershipsTitle}
      isMobileCollapseOpen={isMobileCollapseOpen}
    >
      {Object.keys(honoraryMemberships).map(club => (
        <p key={club} className="mb-2 mt-2 font-bold text-sm">
          {club}
        </p>
      ))}
      {email && (
        <a
          href={`mailto:${email}`}
          className="mb-2 text-sm"
          onClick={() => trackClick(`mailto:${email}`)}
        >
          {email}
        </a>
      )}
    </HonoraryMembershipMobileCollapseHeader>
  ) : (
    <HonoraryMembershipCollapseHeader
      title={honoraryMembershipsTitle}
      collapseInitialState={false}
    >
      {Object.keys(honoraryMemberships).map(club => (
        <p key={club} className="mb-2 mt-2 font-bold text-sm">
          {club}
        </p>
      ))}
    </HonoraryMembershipCollapseHeader>
  );

  return (
    <div>
      {!isMembershipEmpty(honoraryMemberships) &&
        (onlyHonoraryMember ? (
          <div>
            <p className="mb-2 mt-2 text-sm">{honoraryMembershipsTitle}</p>
            {Object.keys(honoraryMemberships).map(club => (
              <p key={club} className="mb-2 mt-2 font-bold text-sm">
                {club}
              </p>
            ))}
          </div>
        ) : (
          collapseHeader
        ))}
    </div>
  );
};

export default DisplayHonoraryMemberships;
