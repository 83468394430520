import React, { useEffect, useState } from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import { TwoColumns } from '@components/Layouts/TwoColumns';
import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';

import MemberSearchForm from './MemberSearchForm';
import MemberSearchList from './MemberSearchList';

import { MemberSearchFormValues } from '@domain/clubs';

import {
  doesUserHaveMembership,
  getMemberParamsFromURL,
  validateMemberParamsFromURL,
} from '@use-cases/districts';

import { useUserAccount } from '@repositories/auth/hooks';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useMenu } from '@hooks/menu';
import { useReset } from '@hooks/useReset';

import { ClubAffiliation } from '@typings/operations';

const MemberSearch: React.FC = location => {
  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState<MemberSearchFormValues>({});
  const { resetFlag, setResetFlag, handleReset } = useReset();
  const {
    data: submenuData,
    error: submenuError,
    loading: submenuLoading,
  } = useMenu('menu-my-rotary-search-navigation', i18n.language);

  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAppConfig();

  const individualIdForFetch =
    user?.isLoggedIn && user?.individualId ? user.individualId : null;
  const { data, loading } = useUserAccount(individualIdForFetch);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  useEffect(() => {
    const filtersFromURL = getMemberParamsFromURL();
    const validatedFilters = validateMemberParamsFromURL(filtersFromURL);
    setFilters({ ...filters, ...validatedFilters });
  }, []);

  if (loading) {
    return <Loading />;
  }

  const clubAffiliations =
    (data?.getIndividual?.clubAffiliations as ClubAffiliation[]) || [];
  const canUserSearchMembers = user?.isLoggedIn
    ? doesUserHaveMembership(clubAffiliations)
    : true;

  if (!canUserSearchMembers) {
    return (
      <OneColumn>
        <AccessDenied />
      </OneColumn>
    );
  }

  return (
    <>
      <OneColumn>
        <Title headTitle={t('metadata.title.search-member', 'Member Search')}>
          {t('search.title', 'Search')}
        </Title>
        {!submenuLoading && !submenuError && submenuData.length && (
          <Submenu items={submenuData} location={location} />
        )}
      </OneColumn>
      <TwoColumns
        className="search-page"
        bigColumnClassName="desktop-l:pl-16"
        small={
          <MemberSearchForm
            onSubmit={setFilters}
            handleReset={handleReset}
            setResetFlag={setResetFlag}
          />
        }
        big={
          <MemberSearchList
            filters={{
              ...filters,
            }}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resetFlag={resetFlag}
          />
        }
      />
    </>
  );
};

export default MemberSearch;
