import React, { useEffect } from 'react';

import Select, { SelectProps, useOptions } from '../Select';

import { useFetchAllDistricts } from '@repositories/districts';

export const useDistrictsOptions = () => {
  const [fetchAllDistricts, { data, error, loading }] = useFetchAllDistricts();

  useEffect(() => {
    fetchAllDistricts({
      variables: {
        status: 'active',
      },
    });
  }, []);

  const options = data?.districts
    ?.map(district => ({
      label: String(district.riDistrictId),
      value: String(district.riDistrictId),
    }))
    .sort(
      ({ value: firstDistrict }, { value: secondDistrict }) =>
        Number(firstDistrict) - Number(secondDistrict)
    );

  return {
    options,
    loading,
    error: error?.message,
  };
};

export const DistrictsSelect: React.FC<Omit<
  SelectProps,
  'options'
>> = props => {
  const useContextualRoleOptions: useOptions = () => {
    return useDistrictsOptions();
  };
  return <Select {...props} options={useContextualRoleOptions} />;
};
