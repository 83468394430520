import React, { PropsWithChildren } from 'react';

import Collapse from '@kunukn/react-collapse';

export type HonoraryMembershipMobileCollapseHeaderProps = {
  honoraryMembershipsTitle: string;
  isMobileCollapseOpen: boolean;
  children: React.ReactNode;
};

const HonoraryMembershipMobileCollapseHeader = ({
  honoraryMembershipsTitle,
  isMobileCollapseOpen,
  children,
}: PropsWithChildren<HonoraryMembershipMobileCollapseHeaderProps>) => {
  return (
    <>
      <div
        className="mr-auto inline-flex items-center"
        data-testid="honorary-membership-mobile-header"
      >
        <div className="desktop:flex desktop:flex-1 desktop:items-center">
          {honoraryMembershipsTitle}
        </div>
      </div>
      <Collapse
        isOpen={isMobileCollapseOpen}
        className="collapse-css-transition mb-4 pt-2"
      >
        {children}
      </Collapse>
    </>
  );
};

export default HonoraryMembershipMobileCollapseHeader;
