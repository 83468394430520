import React, { useEffect, useRef } from 'react';

import { omit, values } from 'lodash';

import Loading from '@components/Loading';
import { Pagination } from '@components/Pagination';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import MemberSearchResult from './MemberSearchResult';
import MemberSearchSummary from './MemberSearchSummary';

import { MemberSearchFormValues } from '@domain/clubs';

import { useErrorHandling } from '@use-cases/notifications';

import { useMemberSearch } from '@repositories/clubs';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';

type MemberSearchListProps = {
  filters: MemberSearchFormValues;
  currentPage: number;
  resetFlag: boolean;
  setCurrentPage: (page: number) => void;
};

const MemberSearchList: React.FC<MemberSearchListProps> = ({
  filters,
  currentPage,
  setCurrentPage,
  resetFlag,
}) => {
  const pageSize = 10;
  const topOfTheList = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { fireTrackingEvent } = useAnalytics();

  // We have to delete the location from graphql query as it was not available in UI.
  if (filters.location) {
    // eslint-disable-next-line no-param-reassign
    delete filters.location;
  }
  const [club] = filters.club ? filters.club.split('-') : ['', ''];
  const { data, loading, error } = useMemberSearch(
    { ...filters, club },
    currentPage
  );
  useErrorHandling(error?.message, !!error, 'member-search.error');

  let numberOfFilters = values(
    omit(filters, [
      'name',
      'email',
      'memberClubType',
      'city',
      'country',
      'stateId',
    ])
  ).filter(item => Boolean(item) && item.length).length;

  if (Boolean(filters.city) || Boolean(filters.country)) {
    numberOfFilters += 1;
  }

  const noFiltersError = t(
    'member-search.error.no-filters',
    'Please provide at least one search parameter.'
  );

  const pageHandler = (event: React.SyntheticEvent, pageNumber: number) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Fire analytics event when we have the initial search results and a search term.
    if (data && currentPage === 1) {
      if (data.members.totalCount !== 0) {
        fireTrackingEvent('searchResults', {
          searchTerm: filters.name,
          searchTotalResults: data.members.totalCount,
          searchType: 'Member Search',
          searchFilters: filters,
        });
      } else {
        // Separate out the event if zero results
        fireTrackingEvent('searchResultsEmpty', {
          searchTerm: filters.name,
          searchType: 'Member Search',
          searchFilters: filters,
        });
      }
    }

    // Scroll to top when data is updated
    topOfTheList.current?.scrollIntoView();
  }, [data]);

  if (numberOfFilters < 0 || !data) {
    return <p>{noFiltersError}</p>;
  }

  if (loading) {
    return <Loading />;
  }

  if (resetFlag) return null;

  return (
    <>
      <ResultsList
        listId="search-results-list"
        summary={
          <MemberSearchSummary
            showing={data.members.results.length}
            total={data.members.totalCount}
            name={filters.name}
            email={filters.email}
            location={filters.location}
            numberOfFilters={numberOfFilters}
            ref={topOfTheList}
          />
        }
      >
        {data.members.results.map((member, index) => (
          <ResultsListItem key={member?.email || index}>
            {/* Add the "search rank" to results (which number link is clicked in results) */}
            <MemberSearchResult
              {...member}
              searchRank={pageSize * (currentPage - 1) + (index + 1)}
            />
          </ResultsListItem>
        ))}
      </ResultsList>

      {data.members.totalCount > 0 && (
        <Pagination
          pageSize={pageSize}
          page={currentPage}
          totalCount={data.members.totalCount}
          pageHandler={pageHandler}
        />
      )}
    </>
  );
};

export default MemberSearchList;
